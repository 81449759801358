import React from "react";
import { Map, Placemark, YMaps } from "react-yandex-maps";

const YandexMaps = () => {
  return (
    <YMaps>
      <Map
        width="100%"
        height="100%"
        defaultState={{ center: [53.30, 83.80], zoom: 14 }}
      >
        <Placemark
          geometry={[53.29970232794437,83.80241994401408]}
          options={{ iconColor: '#E19C67'}}
          properties={{iconCaption: 'Затонские бани'}}
        />
      </Map>
    </YMaps>
  );
};

export default YandexMaps;
/**Cекция. Почему стоит выбрать нас*/
import React from 'react';
import aboutImage from '../images/Main/bany1.webp';
import "../css/font-awesome.css"

const About = () => {

  return (
    <div className="container container__about">
      <div className="text-center main-title-block"><h2 className="with-underline about-main-title">&laquo;Затонские Бани&raquo;</h2></div>
      <div className="main-content">
        <div className="about-img-wrap">
          <img src="https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,w_600,q_90,f_webp/v1694577775/main_page/baths-winter.webp" className="about-img" alt="Баня"/>
        </div>
        <div className="about-text">
          <p>
            Традиционные русские бани по-чёрному и по-серому. Сруб собран без единого гвоздя по технологии наших предков. Лёгкий пар и свежие веники. Предлагаем травяной чай с мёдом и банное меню.

          </p>
          <p>Нет городского шума и суеты. Расположены в 20 минутах езды из центра Барнаула, в микрорайоне Затон на берегу Оби.</p>
          <p>Насладитесь приятным отдыхом.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
import React from "react";
import ScrollToTop from "react-scroll-to-top";

const ScrollButton = () => (
  <ScrollToTop
    smooth
    style={{
      backgroundColor: "#fff",
      borderRadius: "50%",
      transition: "box-shadow 0.3s",
      bottom: "100px"
    }}
    component={<i className="fa fa-angle-double-up" />}
  />
);

export default ScrollButton;
import blackBanya from "../images/black-2.webp";
import chan from "../images/Main/chan.webp";
import grayBanya from "../images/Description/bany_ser.webp"
import slide1 from "../images/black.webp";
import slide2 from "../images/black-2.webp";
import slide3 from "../images/black.webp";
import slide4 from "../images/black-2.webp";

export const services = [
  {
    id: 1,
    slug: "banya-po-chernomu",
    mainImage: 'https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,w_600,q_90,f_webp/v1694675388/main_page/photo-236_1_cbifdf.webp',
    title: "Баня по–чёрному",
    price: 173,
    weekendPrice: 2500,
    weekdayPrice: 2000,
    excerpt: "Дым и сильно разогретые камни при растопке, стерилизуют парную. Пар получается сухой и лёгкий. Посещение бани укрепляет иммунитет.",
    availableForSale: true,
    initialVariant: {shopifyId: 9},
    times: [
      "08:00 - 10:00",
      "10:00 - 12:00",
      "12:00 - 14:00",
      "14:00 - 16:00",
      "16:00 - 18:00",
      "18:00 - 20:00",
    ],
    gallery: [
      {
        id: 1,
        image: slide1,
        alt: "слайд1"
      },
      {
        id: 2,
        image: slide2,
        alt: "слайд2"
      },
      {
        id: 3,
        image: slide3,
        alt: "слайд3"
      },
      {
        id: 4,
        image: slide4,
        alt: "слайд4"
      },
    ]
  },
  {
    id: 2,
    slug: "banya-po-seromu",
    mainImage: 'https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,w_600,q_90,f_webp/v1694675388/main_page/photo-215_1_vuxbd6.webp',
    title: "Баня по–серому",
    rating: 5,
    price: 173,
    weekendPrice: 1500,
    weekdayPrice: 1000,
    excerpt: "Влажный пар и мягкий жар. Идеальный вариант для тех, кто любит баню не только за\n" +
      "целебный и бодрящий эффект, но и ценит настоящий аромат дыма.",
    availableForSale: true,
    initialVariant: {shopifyId: 9},
    times: [
      "08:00 - 10:00",
      "10:00 - 12:00",
      "12:00 - 14:00",
      "14:00 - 16:00",
      "16:00 - 18:00",
      "18:00 - 20:00",
    ]
  },
  {
    id: 3,
    slug: "chan",
    mainImage: "https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,w_600,q_90,f_webp/v1694593969/main_page/photo-207_1_tomfyz.webp",
    title: "Чан",
    rating: 5,
    price: 600,
    weekendPrice: 1500,
    weekdayPrice: 1000,
    excerpt: "Теплая вода с хвойными ветками на свежем воздухе позволит расслабиться. Снимет переутомление, боли в мышцах и суставах. Закажите чан вместе с баней. \n",
    availableForSale: true,
    initialVariant: {shopifyId: 9},
    times: [
      "08:00 - 10:00",
      "10:00 - 12:00",
      "12:00 - 14:00",
      "14:00 - 16:00",
      "16:00 - 18:00",
      "18:00 - 20:00",
    ]
  }
]
import React, { useState } from "react";
import mapImg from "../images/Main/map.webp";
import MapModal from "../components/map-modal";

const MapImg = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className="container container__service" id="service">
        <div className="service-content service-content_map">
          <div
            className="map-img-wrap"
            onClick={openModal}
            onKeyDown={openModal}
            role="button"
            tabIndex={0}
          >
            <img src={mapImg} alt="схема проезда" className="map-img-pic"  />
          </div>
          <div className="map-img-desc">
            <h4 className="map-img-subtitle">Транспорт:</h4>
            <div className="map-img-transport">
              <i className="fa fa-bus"></i>
              <span>Автобус № 40</span>
            </div>
          </div>
        </div>
      </div>
      {
        modalIsOpen && (
          <MapModal
            closeModal={closeModal}
            modalIsOpen
          />
        )
      }
    </>
  );

};

export default MapImg;
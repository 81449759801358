import React from "react";
import Modal from "react-modal";
import YandexMaps from "./yandex-map"

Modal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#E19C67",
    padding: "5px"
  }
};

const MapModal = ({modalIsOpen, closeModal}) => {

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="main-content main-content__modal main-content__map">
          <button onClick={closeModal} className="modal-close modal-close__map" aria-label="close modal"><i
            className="fa fa-window-close" /></button>
          <YandexMaps/>
        </div>
      </Modal>
    </div>
  );
};

export default MapModal;
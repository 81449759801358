/**Все услуги*/
import React from 'react';
import ServiceCard from "./service-card";
import {services} from "../consts/services";


const Services = () => {

  return (
    <div className="container container__service">
      <div className="service-content" itemScope itemType="https://schema.org/Service">
        {services.map(service => (
          <ServiceCard
            service={service}
            key={service.id}
          />
        ))}
      </div>
    </div>
  );
}

export default Services;
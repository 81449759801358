/**Главная страница*/
import React from "react";
import Layout from "../components/layout";
import Banner from "../components/banner";
import Services from "../components/services";
import About from "../components/about";
import MapImg from "../components/map-img";
import ScrollButton from "../components/scroll-button";

const IndexPage = () => {

  return (
    <Layout
      metaContent="Традиционные русские бани по-чёрному и по-серому. Сруб собран без единого гвоздя по технологии наших предков. Лёгкий пар и свежие веники. Предлагаем травяной чай с мёдом и банное меню."
      title="Затонские Бани"
      keywords="затонские бани барнаул, бани затон, заказ бани в барнауле, барнаул баня с горячим чаном, баня с чаном барнаул"
    >
      <ScrollButton />
      <Banner
        anchor="service"
        buttonText="Подробнее"
      />
      <About />
      <Services />
      <MapImg />
    </Layout>
);
};
export default IndexPage;
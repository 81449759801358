import React from "react";
import {Link} from "gatsby";
import {StaticQuery, graphql} from "gatsby";
// import { useAuthValue } from "./Auth/AuthContext";

const ServiceCard = ({service}) => {
  const {
    excerpt,
    title,
    mainImage
  } = service;

  /**Получаем статус пользователя*/
  // const { currentUser } = useAuthValue();

  return (
    <div className="details-wrap">
      <div className="details-pic">
        <img
          itemProp="image"
          src={mainImage}
          alt={title}
          title={title}
          className="details-img"
        />
      </div>
      <div className="details_inner">
        {/**Ссылка на отдельную услугу*/}
        <h2 itemProp="name">{title}</h2>
        {/**Короткое описание*/}
        <p itemProp="description">
          {excerpt}
        </p>
      </div>
      {/**Ссылка на страницу сервиса*/}
      <div className="details-control">
        <Link
          className="details-link"
          to={title === "Чан" ? "/description-chan/" : "/description/"}
        >
          Подробнее
        </Link>
        {
        /*  // title !== "Чан" && <a href={ currentUser ? "/order/" : "http://localhost:3000/" } className="snipcart-add-item details-btn">Заказать</a>*/
          title !== "Чан" && <Link to="/order/" className="snipcart-add-item details-btn">Заказать</Link>
        }
      </div>
    </div>
  );

};

export default ServiceCard;
